<template>
  <v-footer dark padless>
    <v-card flat tile class="black white--text py-5 px-5 text-center" width="100%">
      <v-row>
        <v-col cols="12" sm="4">
          <v-card-text class="white--text pt-2">
            <v-btn icon>
              <v-img src="logo.png" max-height="40" max-width="40" contain></v-img>
            </v-btn>
            <h2>{{ $t('site.title') }}</h2>
          </v-card-text>
        </v-col>


        <v-col cols="12" sm="4">
          <v-card-text class="pt-2">
            <v-btn v-for="(rrss, index) in icons" :key="index" class="mx-1 white--text" icon>
              <a :href="rrss.link" target="_blank">
                <v-icon size="24px" color="#efb810">
                  {{ rrss.icon }}
                </v-icon>
              </a>
            </v-btn>
          </v-card-text>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card-text class="pt-1">
            <div>
              <v-btn class="mx-1 white--text" icon>
                <v-icon color="#efb810">mdi-phone</v-icon>
              </v-btn>
              <a href="" style="text-decoration: none; color: #efb810;">+1 (973) 464-5559</a>
            </div>
            <div>
              <v-btn class=" white--text" icon>
                <v-icon color="#efb810">mdi-email</v-icon>
              </v-btn>
              <a href="" style="text-decoration: none; color: #efb810;">d.alexupholstery@gmail.com</a>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    //icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    icons:[
      {
        icon: "mdi-facebook",
        link: "https://www.facebook.com"
      },
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/d.alexupholstery"
      }
    ]
  })
};
</script>

<style></style>