<template>
	<v-row>
		<v-col cols="12" style="display: flex; flex-wrap: wrap; justify-content: center;">
			<div class="child" style="flex: 1 0 auto; margin: 10px; max-width: 350px; "  :class="index % 2 === 0 ? 'bgColor1' : 'bgColor2'" v-for="(service, index) in services" :key="index">
				<v-icon :color="color(index)" x-large class="ml-3" dark>{{ service.icon }}</v-icon>
				<h3 class="ml-3 mt-4" :class="index % 2 === 0 ? 'white--text' : 'black--text'">{{ service.title }}</h3>
				<p class="ml-3 mt-6"  :class="index % 2 === 0 ? 'grey--text' : 'black--text'" align="justify" >{{ service.description }}</p>
				<v-btn :color="color(index)" dark text to="/about">
					{{ $t('buttons.readmore') }}
					<v-icon right>mdi-arrow-right</v-icon>
				</v-btn>
			</div>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "Services",
	data: () => ({
		services: []
	}),
	methods: {
		color(index) {
			return index % 2 == 0 ? "#efb810" : "#1c1c1c";
		}
	},
	mounted() {
		let services_es = require(`../../data/services/es.json`);
		let services_en = require(`../../data/services/en.json`);
		let services = {
			es: services_es,
			en: services_en
		};
		this.services = services[this.$i18n.locale].data;
	},
	watch: {
		'$i18n.locale': function (newVal) {

			let services = {
				es: require(`../../data/services/es.json`),
				en: require(`../../data/services/en.json`)
			};
			this.services = services[newVal].data;
		}
	}
}
</script>

<style></style>