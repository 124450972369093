<template>
  <v-app 
      id="home"
      :style="{ background: $vuetify.theme.themes.dark.background }"
    >
    <v-main>
      <NavBar />
      <router-view/>
      <Footer />
      <ButtonsFloating />
    </v-main>
  </v-app>
</template>
<script>

import ButtonsFloating from './components/ButtonsFloating.vue';
import NavBar from './components/NavBar.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    ButtonsFloating,
    NavBar,
    Footer
},
  data: () => ({
    //
  }),
};
</script>
