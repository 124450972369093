<template>
  <!-- <v-app> -->
  <v-container fluid flex wrap>
    <v-row>
      <v-row lg="12">
        <v-col md="12" lg="6">
          <v-img src="logo2.png" contain :max-height="$vuetify.breakpoint.xsOnly ? '250' : '500'"></v-img>
        </v-col>
        <v-col md="12" lg="6" :class="$vuetify.breakpoint.xsOnly ? 'ml-4' : ''">
          <h5 class="text--darken-4" :class="$vuetify.breakpoint.xsOnly ? '' : 'top'" style="color: #efb810;">{{
            $t('site.title') }}</h5>
          <h1 class="white--text">{{ $t('pages.home.section.header.hi') }}</h1>
          <p class="white--text">{{ $t('pages.home.section.header.description') }}</p>
          <v-btn color="#efb810" rounded dark to="/about"> {{ $t('buttons.about') }} </v-btn>
        </v-col>
      </v-row>

      <v-row cols class="pb-10">
        <v-col md="12" cols="12" class="padd">
          <div class="first" id="services">
            <Services />
          </div>
        </v-col>
      </v-row>

      <v-row class="px-5" lg="12" md="6">
        <v-col offset-md="3" md="3" id="project">
          <h4 class="white--text">{{ $t('pages.home.section.projects.title') }}</h4>
          <p class="white--text">{{ $t('pages.home.section.projects.description') }}</p>
        </v-col>
        <v-col offset-md="2" md="3" class="text-start">
          <v-btn tile color="#efb810"> {{ $t('buttons.seeall') }} </v-btn>
        </v-col>
      </v-row>

      <v-row lg="12" md="6">
        <v-col md="3" offset-md="3">
          <v-card class="pa-2 py-6" outlined tile color="#1E1E1E">
            <v-img src="assets/images/gallery/h1.jpg" style="object-fit: contain;"></v-img>
            <v-card-actions>
              <v-row>
                <v-col md="6">
                  <v-btn color="white" dark text class="ml-n4"> The Vintage </v-btn>
                </v-col>
                <v-col md="6">
                  <v-btn color="#efb810" dark text class="ml-n4 float-right" to="/gallery">
                    {{ $t('buttons.seemore') }}
                    <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="3">
          <v-card class="pa-2 py-6" outlined tile color="#1E1E1E">
            <v-img src="assets/images/gallery/h2.jpg"></v-img>
            <v-card-actions>
              <v-row>
                <v-col md="6">
                  <v-btn color="white" dark text class="ml-n4"> Foodasa </v-btn>
                </v-col>
                <v-col md="6">
                  <v-btn color="#efb810" dark text class="ml-n4 float-right" to="/gallery">
                    {{ $t('buttons.seemore') }}
                    <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="3" offset-md="3">
          <v-card class="pa-2 py-6" outlined tile color="#1E1E1E">
            <v-img src="assets/images/gallery/h3.jpg"></v-img>
            <v-card-actions>
              <v-row>
                <v-col md="6">
                  <v-btn color="white" dark text class="ml-n4"> AAE IdeaPro </v-btn>
                </v-col>
                <v-col md="6">
                  <v-btn color="#efb810" dark text class="ml-n4 float-right" to="/gallery">
                    {{ $t('buttons.seemore') }}
                    <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="3">
          <v-card class="pa-2 py-6" outlined tile color="#1E1E1E">
            <v-img src="assets/images/gallery/h4.jpg"></v-img>
            <v-card-actions>
              <v-row>
                <v-col md="6">
                  <v-btn color="white" dark text class="ml-n4"> Mozaik </v-btn>
                </v-col>
                <v-col md="6">
                  <v-btn color="#efb810" dark text class="ml-n4 float-right" to="/gallery">
                    {{ $t('buttons.seemore') }}
                    <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <Contact />
    </v-row>
    <v-col md="12">
      <div style="justify-content: center; display: flex; flex-wrap: wrap;">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d753.4860922832223!2d-74.14747113044123!3d40.938886411292756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2fc8b2f4b31af%3A0x17ae4ee569e09400!2s58%202nd%20Ave%20%2360%2C%20Paterson%2C%20NJ%2007514%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sve!4v1711597524573!5m2!1ses-419!2sve"
          width="800" height="300" style="border:0;" allowfullscreen="" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </v-col>
  </v-container>
  <!-- </v-app> -->
</template>

<script>
import Services from "../components/home/Services.vue";
import Contact from "../components/home/Contact.vue";

export default {
  name: "Home",
  data: () => ({
    
  }),
  components: {
    Services,
    Contact
  },
};
</script>
<style >
.top {
  margin-top: 180px;
}
.topInverse {
  margin-top: -250px;
}
.topTolbar {
  margin-top: 100px;
  text-align: center;
}
.first {
  width: 100%;
  height: auto;
  background: linear-gradient(
    to right,
    #181818,
    #181818 50%,
    #111111 50%,
    #111111 50%
  );
  text-align: center;
  padding: 2rem 2rem;
}
.second {
  width: 100%;
  height: auto;
  background: #181818;
  text-align: center;
  padding: 2rem 2rem;
}
.secondchild1 {
  display: inline-block;
  background-color: #1e1e1e;
  padding: 2rem 2rem;
  vertical-align: middle;
  text-align: left;
  margin-top: 250px;
}
.child {
  display: inline-block;
  padding: 2rem 1rem;
  vertical-align: middle;
  text-align: left;
  margin-right: 8px;
}
.bgColor1 {
  background-color: #1e1e1e;
}
.bgColor2 {
  background-color: #efb810;
}

.child1 {
  display: inline-block;
  padding: 2rem 1rem;
  vertical-align: middle;
  margin-right: 5px;
  width: 240px;
}
.child2 {
  display: inline-block;
  width: 245px;
  vertical-align: middle;
}
.mRight {
  margin-right: 8px;
}
.mButton {
  margin-bottom: 8px;
}
.padding {
  padding: 8px 0;
}

.col-12.padd {
  padding: 12px 0 !important;
}
.col-12.childcol {
  padding: 0 !important;
}
h1.number {
  font-size: 50px;
  font-weight: bold;
}
</style>