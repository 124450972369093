<template>
	<div>
		<a class="tooltip" :href="`https://api.whatsapp.com/send?phone=+1(973)464-5559&text=Tengo%20una%20consulta:%20Estoy%20en:%20${routePath}`" id="ws-button" target="_blank">
			<img src="icon-ws.png" alt="whatsapp d'alex tapiceria">
			<span class="tooltip-text">¿Tienes una duda? ¡Contáctanos!</span>
		</a>
		<button id="up-arrow-button">
			<img src="assets/images/up-arrow.png" alt="alternative" />
		</button>
	</div>
</template>

<script>
export default {
	name: "ButtonsFloating",
	computed: {
		routePath() {
			return window.location.href;
		}
	},
};
</script>

<style>

#ws-button img {
	margin-bottom: 0.25rem;
	width: 50px;
	margin-top: 20%;
}

#ws-button {
	position: fixed;
	z-index: 99;
	bottom: 85px;
	right: 20px;
	display: none;
	width: 52px;
	height: 52px;
	border: none;
	border-radius: 50%;
	outline: none;
	/* background-color: #44434a; */
	cursor: pointer;
	display: block;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 160px;
  background-color: rgba(63, 63, 63, 0.4);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  bottom: 125%; 
  left: 50%;
  margin-left: -120px;

  opacity: 1;
  transition: opacity 0.3s;
}

.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 80%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

#up-arrow-button:hover {
	background-color: #1d1d21;
}

#up-arrow-button img {
	margin-bottom: 0.25rem;
	width: 18px;
	margin-top: 20%;
}

#up-arrow-button {
	position: fixed;
	z-index: 99;
	bottom: 20px;
	right: 20px;
	display: none;
	width: 52px;
	height: 52px;
	border: none;
	border-radius: 50%;
	outline: none;
	background-color: #44434a;
	cursor: pointer;
}

#up-arrow-button:hover {
	background-color: #1d1d21;
}


#up-arrow-button img {
	margin-bottom: 0.25rem;
	width: 18px;
	margin-top: 20%;
}
</style>