<template>
  <v-container fluid>
    <div class="first" style="height: auto;">
      <v-row class="mb-5" style="justify-content: center;">
        <h1 class="white--text text--darken-4" style="justify-content: center;"> {{ $t('pages.about.history.title') }}
        </h1>
      </v-row>
      <v-row cols="12">
        <v-col>
          <div class="child" style="background-color: rgba(63, 63, 63, 0.6);border-radius: 6px;">
            <h1 class="white--text ml-3 mt-1">{{ $t('pages.about.history.subtitle') }}</h1>
            <p class="white--text ml-3 mt-1" align="justify" v-html="$t('pages.about.history.description')">
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-5" style="justify-content: center;">
        <h1 class="white--text text--darken-4" style="justify-content: center;"> {{ $t('pages.about.title') }}</h1>
      </v-row>
      <v-row cols="12">
        <v-col lg="6" md="6" sm="12" class="col-content">
          <div class="child" style="background-color: rgb(239, 184, 16, 0.8); height: auto; border-radius: 6px;object-fit: contain;">
            <h1 class="white--text ml-3 mt-1">{{ $t('pages.about.mission.title') }}</h1>
            <p class="white--text ml-3 mt-1" v-html="$t('pages.about.mission.description')">
            </p>
          </div>
        </v-col>
        <v-col lg="6" md="6" sm="12" class="col-content">
          <div class="child" style="background-color: rgb(239, 184, 16, 0.8); height: auto;  border-radius: 6px;object-fit: contain;">
            <h1 class="white--text ml-3 mt-1">{{ $t('pages.about.vision.title') }}</h1>
            <p class="white--text ml-3 mt-1" v-html="$t('pages.about.vision.description')">
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-5" style="justify-content: center;">
        <h1 class="white--text text-darken-5" style="justify-content: center;"> {{ $t('pages.about.value.title') }}</h1>
      </v-row>
      <v-row cols="12" style="justify-content: center;">
        <v-col md="6" sm="12" v-for="(item, index) in $t('pages.about.values')" :key="index" style="">
          <div class="child" style="height: auto;background-color: rgba(63, 63, 63, 0.6); border-radius: 6px;">
            <h3 class="white--text ml-2">{{ item.title }}</h3>
            <p class="white--text ml-2 mt-1" v-html="item.description">
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>

export default {
  name: "About",
  data: () => ({

  })
};
</script>
<style>
.background {
  width: 100%;
  background: linear-gradient(to right,
      #181818,
      #181818 50%,
      #111111 50%,
      #111111 50%);
}

.child {
  display: inline-block;
  padding: 2rem 1rem;
  vertical-align: middle;
  text-align: left;
  margin-right: 8px;
}
</style>
