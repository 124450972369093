<template>
	<div>
		<v-overlay :value="drawer" z-index="4" v-if="isMobile">
		</v-overlay>
		<v-app-bar app clipped-right dark color="#111111" v-if="isMobile">
			<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title>{{ $t('site.title') }}</v-toolbar-title>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" app clipped hide-overlay v-if="isMobile"
			:style="{ top: $vuetify.application.top + 'px', zIndex: 4 }">
			<v-divider></v-divider>
			<v-btn block text color="#efb810" @click="scroll('home')" to="/">{{ $t('navigation.home')
				}}</v-btn>
			<v-btn block text color="#efb810" to="/about">{{ $t('navigation.about') }}</v-btn>
			<v-btn block text color="#efb810" @click="scroll('services')">{{ $t('navigation.services') }}</v-btn>
			<v-btn block text color="#efb810" @click="scroll('project')">{{ $t('navigation.projects') }}</v-btn>
			<v-btn block text color="#efb810" to="/gallery">{{ $t('navigation.gallery') }}</v-btn>

			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn text color="#efb810" dark v-bind="attrs" v-on="on" block>{{ langLabel }}</v-btn>
				</template>
				<v-list>
					<v-list-item v-for="(item, index) in langs" :key="index" @click="handleChangeLang(item.value)">
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-btn class="" color="#efb810" @click="scroll('contact')" block rounded outlined dark> {{
				$t('navigation.getquote')
			}}</v-btn>
		</v-navigation-drawer>
		<v-app-bar app color="#111111" dark flat class="px-8" id="navbar" v-if="!isMobile">
			<h2 style="padding-left: 10px;">{{ $t('site.title') }}</h2>
			<v-spacer></v-spacer>
			<v-btn class="p-2" text color="#efb810" @click="scroll('home')" to="/">{{ $t('navigation.home') }}</v-btn>
			<v-btn text color="#efb810" to="/about">{{ $t('navigation.about') }}</v-btn>
			<v-btn text color="#efb810" @click="scroll('services')">{{ $t('navigation.services') }}</v-btn>
			<v-btn text color="#efb810" @click="scroll('project')">{{ $t('navigation.projects') }}</v-btn>
			<v-btn text color="#efb810" to="/gallery">{{ $t('navigation.gallery') }}</v-btn>
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn text color="#efb810" dark v-bind="attrs" v-on="on">{{ langLabel }}</v-btn>
				</template>
				<v-list>
					<v-list-item v-for="(item, index) in langs" :key="index" @click="handleChangeLang(item.value)">
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-btn class="ml-2" color="#efb810" @click="scroll('contact')" rounded outlined dark> {{
				$t('navigation.getquote')
				}}</v-btn>
		</v-app-bar>
	</div>



	<!--<v-app-bar app color="#111111" dark flat class="px-8" id="navbar">
		<h2 style="padding-left: 10px;">{{ $t('site.title') }}</h2>
		<v-spacer></v-spacer>
		<v-btn class="p-2" text color="#efb810" @click="scroll('home')" to="/">{{ $t('navigation.home') }}</v-btn>
		<v-btn text color="#efb810" to="/about">{{ $t('navigation.about') }}</v-btn>
		<v-btn text color="#efb810" @click="scroll('services')">{{ $t('navigation.services') }}</v-btn>
		<v-btn text color="#efb810" @click="scroll('project')">{{ $t('navigation.projects') }}</v-btn>
		<v-btn text color="#efb810" to="/gallery">{{ $t('navigation.gallery') }}</v-btn>
		<v-menu offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn text color="#efb810" dark v-bind="attrs" v-on="on">{{ langLabel }}</v-btn>
			</template>
			<v-list>
				<v-list-item v-for="(item, index) in langs" :key="index" @click="handleChangeLang(item.value)">
					<v-list-item-title>{{ item.text }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
		<v-btn class="ml-2" color="#efb810" @click="scroll('contact')" rounded outlined dark> {{
			$t('navigation.getquote')
		}}</v-btn> 
	</v-app-bar>-->

</template>

<script>
export default {
	data: () => ({
		drawer: false,

		menu: [
			{ icon: 'home', title: 'Link A' },
			{ icon: 'info', title: 'Link B' },
			{ icon: 'warning', title: 'Link C' }
		],
		langs: [
			{
				text: 'ENG',
				value: 'en'
			},
			{
				text: 'ESP',
				value: 'es'
			}
		],
		lang: 'es'
	}),
	computed: {
		langLabel() {
			return this.lang == 'es' ? 'ESP' : 'ENG';
		},
		isMobile() {
			return this.$vuetify.breakpoint.xsOnly;
		}
	},
	methods: {
		scroll(refName) {
			if (this.$route.path !== '/') {
				this.$router.push('/').then(() => {
					this.$nextTick(() => {
						const element = document.getElementById(refName);
						element.scrollIntoView({ behavior: "smooth" });
					});
				});
			} else {
				const element = document.getElementById(refName);
				element.scrollIntoView({ behavior: "smooth" });
			}
		},
		handleChangeLang() {
			this.$i18n.locale = this.lang == 'es' ? 'en' : 'es';
		}
	},
	mounted() {
		this.lang = this.$i18n.locale;
	},
	watch: {
		'$i18n.locale': function (newVal) {
			this.lang = newVal;
		}
	}
};
</script>

<style>
.v-navigation-drawer__content {
	background: #181818
}
</style>