<template>
	<div class="block galleryBlock">
		<v-container>
			<h2 class="text-center" style="color: #efb810;">{{ $t('navigation.gallery')}}</h2>
			<v-row>
				<v-col v-for="item in items" :key="item.id" class="d-flex child-flex" cols="6" sm="4">
					<v-card flat tile class="d-flex">
						<v-img :src="item.src" aspect-ratio="1" class="grey lighten-2">
							<template v-slot:placeholder>
								<v-row class="fill-height ma-0" align="center" justify="center">
									<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
								</v-row>
							</template>
						</v-img>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
export default {
	name: "Gallery",
	data() {
		return {
			items: [
				{
					id: 1,
					src: "assets/images/gallery/1.jpg"
				},
				{
					id: 2,
					src: "assets/images/gallery/2.jpg"
				},
				{
					id: 3,
					src: "assets/images/gallery/3.jpg"
				},
				{
					id: 4,
					src: "assets/images/gallery/4.jpg"
				},
				{
					id: 5,
					src: "assets/images/gallery/5.jpg"
				},
				{
					id: 6,
					src: "assets/images/gallery/6.jpg"
				},
				{
					id: 7,
					src: "assets/images/gallery/7.jpg"
				},
				{
					id: 8,
					src: "assets/images/gallery/8.jpg"
				},
				{
					id: 9,
					src: "assets/images/gallery/9.jpg"
				}
			]
		};
	}
};
</script>
<style lang="scss">
// block
.block {
  padding: 60px 0;

  @media only screen and (max-width: 599px) {
    padding: 20px 0;
  }

  h2 {
    font-size: 30px;
    padding: 0 0 10px;
    margin: 0 0 40px;
    position: relative;

    @media only screen and (max-width: 599px) {
      font-size: 24px;
      padding: 0 0 5px;
      margin: 0 0 25px;
    }

    &:after {
      content: '';
      background: #000;
      width: 60px;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      @media only screen and (max-width: 599px) {
        width: 30px;
      }
    }
  }
}
</style>