import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueI18n from "vue-i18n";
/* import static content language */
import en from "./locales/en.json";
import es from "./locales/es.json";

Vue.config.productionTip = false

Vue.use(VueI18n);

const lang = window.navigator.language.split("-")[0];

const i18n = new VueI18n({
  locale: lang,
  messages: {
    en,
    es
  }
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
