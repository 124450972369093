<template>
	<v-col cols="12" class="padd topInverse">
		<div class="second">
			<div class="secondchild1" id="contact">
				<v-row>
					<v-col cols="6">
						<h1 class="white--text">
							Let's work together on<br />your next project
						</h1>
						<p class="grey--text">
							Lorem ipsum dolor sit amet consectetur adipisicing elit.
							Eius nesciunt ducimus natus <br />
						</p>
					</v-col>
					<v-col cols="6" style="background-color: #dfac15;">
						<v-form class="pt-5">
							<v-text-field color="#black" label="Name" v-model="form.name"
								:rules="nameRules"></v-text-field>
							<v-text-field color="#black" label="Phone" v-model="form.phone"
								:rules="phoneRules"></v-text-field>
							<v-text-field color="#black" label="Email" v-model="form.email"
								:rules="emailRules"></v-text-field>
							<v-textarea color="#black" label="Description" v-model="form.description"></v-textarea>
						</v-form>
						<v-btn block tile color="#111111" dark class="mt-5" @click="sendMailContact()"
							:loading="sending">
							<v-icon color="#fff">mdi-email-arrow-right-outline</v-icon> &nbsp; Send Contact
						</v-btn>
					</v-col>
				</v-row>
			</div>
		</div>
		<!-- <v-toolbar class="topTolbar" color="#111111" dark flat>
			<div style="
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
              ">
			</div>
		</v-toolbar> -->
	</v-col>
</template>

<script>
export default {
	data: () => ({
		sending: false,
		form: {
			name: "",
			phone: "",
			email: "",
			description: ""
		},
		nameRules: [
			v => !!v || 'Value is required',
			v => v.length >= 6 || 'Value must be more than 6 characters'
		],
		phoneRules: [
			v => !!v || 'Value is required',
			v => v.length >= 10 || 'Value must be less than 10 characters'
		],
		emailRules: [
			v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
		]
	}),
	computed: {
		validSend() {
			return this.form.email == "" || this.form.name == "" || this.form.phone == "" || this.form.description == "" ? false : true;
		}
	},
	methods: {
		async sendMailContact() {

			if (!this.validSend) {
				alert("Please fill all info for form contact.");
				return;
			}

			this.sending = true;

			await fetch("https://dalexupholstery.com/mail/scriptmail.php", {
				method: "POST",
				mode: "no-cors", // no-cors, *cors, same-origin
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(this.form)
			})
				.then(res => {
					console.log("res", res)
					this.form = {
						name: "",
						phone: "",
						email: "",
						description: ""
					};
					setTimeout(() => {
						this.sending = false;
						alert("Contact has send!");
					}, 3000);
				})
				.catch((err) => {
					console.log("err", err)
				});
		}
	}
}
</script>

<style></style>